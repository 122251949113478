import { FC } from 'react';
import ElementProps from '../../types/ElementProps';
import { getValue } from '../../utils/valueUtils';
// hint-edit
const Dg50: FC<ElementProps> = ({ properties, item, onChange }) => {
	var hint = 0;
	let checked = false;
	var key = getValue(item, properties.id);
	if (item.id > 0) {
		if (item.fields[properties.id]) {
			checked = true;
			hint = item.fields[properties.id][key];
		} else {
			checked = false;
			hint = 0;
		}
	} else {
		checked = true;
		item.fields[properties.id][key] = 0;
		hint = 0;
	}
	const toggleHint = () => {
		checked = !checked;
		item.fields[properties.id][key] = checked ? hint : null;
	};
	return null;
}
export default Dg50;