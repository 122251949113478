import Service from './Service';

/**
 * Вебийн тохиргоог удирдах сервис
 */
export default class SiteService extends Service {
    constructor(token: string) {
        super('site', token);
    }
    get = () => fetch(this.alias, {
        method: "GET",
        headers: super.getHeaders()
    });
    edit = (item: any) => fetch(this.alias, {
        method: "PUT",
        headers: super.getHeaders(),
        body: JSON.stringify(item)
    });
}