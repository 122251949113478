import { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useCookies } from 'react-cookie';
import Page from './components/Page';
import PageLayout from './components/PageLayout';
import Category from './components/Category';
import Table from './components/Table';
import Media from './pages/Media';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Register from './pages/Register';
import Password from './pages/Password';
import Root from './pages/Root';
import { AuthContext, SiteContext, UserContext } from './utils/context';
import MeService from './services/MeService';
// import { loadCss } from './utils/domUtils';
import SiteService from './services/SiteService';
import Site from './types/Site';
import User from './types/User';

const handleScroll = () => {
  var offset = 0;
  var className = 'scrolled';
  var regex = /\s*scrolled/i;
  if (window.scrollY > offset) {
    if (!document.body.className.includes(className)) {
      document.body.className += ' ' + className;
    }
  } else {
    document.body.className = document.body.className.replace(regex, '');
  }
}

function App() {
  // Вебийн тохиргоог агуулна.
  const [site, setSite] = useState<Site>();
  // Хандаж буй хэрэглэгчийн мэдээллийг агуулна.
  const [user, setUser] = useState<User>();
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  // JWT bearer token-ийг агуулна.
  // Күүкий дээр хадгалсан токенийг авч глобал хувьсагчид хадгална
  const [token, setToken] = useState(cookies.token);
  // Веб рүү орох үед дуудагдана.
  useEffect(() => {
    // загварын файлыг татна.
    // loadCss("/upload/theme/style.css");
    // Вебийн тохиргоог авна.
    new SiteService(token).get().then(data => data.json()).then(data => {
      console.log('site set')
      setSite(data)
    });
    // Хуудсыг доош нь гүйлгэхэд body тагийн class-д scrolled утгыг нэмнэ
    window.addEventListener('scroll', handleScroll);
    if (window.self !== window.top) {
      // Хэрвээ iframe дотор дуудсан бол
      document.body.className += ' iframe';
    }
  }, []);
  // JWT bearer token өөрчлөгдөх үед дуудагдана.
  useEffect(() => {
    // Хэрэглэгчийн мэдээллийг авна.
    new MeService(token).get().then(data => data.json()).then(data => setUser(data));
    // Токенийг күүкий болон глобал хувьсагчид хадгална
    if (token) {
      setCookie('token', token, { path: '/' })
    } else {
      removeCookie('token')
    }
  }, [token]);
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      children: [
        {
          index: true,
          element: <Page />,
        },
        {
          path: "items/:itemId/:layout.html",
          element: <Page />,
        },
        {
          path: "items/:itemId.html",
          element: <Page />,
        },
        {
          path: "categories/:categoryId/items/:itemId/:layout.html",
          element: <Page />,
        },
        {
          path: "categories/:categoryId/items.html",
          element: <Category />,
        },
        {
          path: "categories/:categoryId/table.html",
          element: <Table />,
        },
        {
          path: "layout/:valueId/node/:nodeId.html",
          element: <PageLayout />,
        },
        {
          path: "media/*",
          element: <Media />,
        },
        {
          path: "media.html",
          element: <Media />,
        },
        {
          path: "login.html",
          element: <Login />,
        },
        {
          path: "logout.html",
          element: <Logout />,
        },
        {
          path: "register.html",
          element: <Register />,
        },
        {
          path: "password.html",
          element: <Password />,
        },
      ]
    },

  ]);
  return site && user ? <SiteContext.Provider value={{ site, setSite }}>
    <AuthContext.Provider value={{ token, setToken }}>
      <UserContext.Provider value={{ user, setUser }}>
        <RouterProvider router={router} />
      </UserContext.Provider>
    </AuthContext.Provider>
  </SiteContext.Provider> : <div>Түр хүлээнэ үү...</div>
}

export default App;