import Service from './Service';

/**
 * Веб үзэж буй хэрэглэгчийн мэдээллийг удирдах сервис
 */
export default class MeService extends Service {
    constructor(token: string) {
        super('users/me', token);
    }
    get = () => fetch(this.alias, {
        method: "GET",
        headers: super.getHeaders()
    });
    signIn = (email: string, password: string) => this.add({ email: email, password: password });
    signOut = () => this.add({});
    newPass = (email: string) => this.add({ email: email });
    signUp = (email: string, password: string) => this.edit({ email: email, password: password });
}