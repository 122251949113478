import { FC, useContext, useState } from 'react';
import ElementProps from '../../types/ElementProps';
import UserService from '../../services/UserService';
import { AuthContext } from '../../utils/context';
import ItemModal from '../modal/ItemModal';
import User from '../../types/User';
// user-edit
const Dg6: FC<ElementProps> = ({ properties, item, onChange, value }) => {
	const { token } = useContext(AuthContext);
	const [visible, setVisible] = useState(false);
	const [items, setItems] = useState([]);
	const [_item, setItem] = useState({} as User);
	const [selected, setSelected] = useState({} as any);
	const refreshItems = (searchText: any) => {
		var filter = searchText == null || searchText.length == 0 ? {} : { text: searchText + '%' };
		new UserService(token).query(filter).then(data => data.json()).then((data: any) => {
			setItems(data.content);
		});
	}
	const add = function () {
		setVisible(true);
		//setItem({ nickname: '', activation: 0, type: -7 });
		setItem({} as any);
	}
	const modalClose = (result: any) => {
		setItems(items.concat(result));
		onChange(result.id);
	}
	return <div className="input-group">
		<div>{value.value}</div>
		<span className="input-group-btn">
			<a href="" className="btn btn-success" onClick={add}><i className="fa fa-plus"></i></a>
		</span>
		<ul className="dropdownlist">
			<li><input value={selected.id + ' ' + selected.title} onChange={refreshItems} /></li>
			{items.map((item: any) => <li key={item}>
				{item.id}. {item.title}
			</li>)}
		</ul>
		<ItemModal visible={false} onClose={modalClose} item={_item} />
	</div>;
}
export default Dg6;