import React, { FC } from 'react';
import { Link } from 'react-router-dom';

const Pagination: FC<PaginationProps> = ({ total, count, size, current }) => {

    var pages: any[] = [];
    const getUrl = (i: number) => {
        var url = new URL(window.location.toString());
        var search_params = url.searchParams;
        // new value of "id" is set to "101"
        search_params.set('page', i.toString());
        // change the search property of the main url
        url.search = search_params.toString();
        // the new url string
        return url.toString();
    };
    if (total > 0) {
        var last = Math.ceil(total / size);
        var start = current > 5 ? current - 5 : 1;
        var end = current + 5 < total / size ?
            current + 5 : last;
        pages.push({
            link: current > 1 ? getUrl(1) : null,
            class: 'first',
        });
        pages.push({
            link: current > 1 ? getUrl(current - 1) : null,
            class: 'prev'
        });
        for (var i = start; i <= end; i++) {
            pages.push({
                link: current !== i ? getUrl(i) : null,
                index: i,
                class: current === i ? 'active' : null
            });
        }
        pages.push({
            link: current < last ? getUrl(current + 1) : null,
            class: 'next',
        });
        pages.push({
            link: current < last ? getUrl(last) : null,
            class: 'last'
        });
    }
    return <div className="pagination">
        <ul>
            {pages.map((page, i) => <li key={i} className={page.class}>
                <Link to={page.link}>{page.index}</Link>
            </li>)}
        </ul>
        <p>Нийт {total} мэдээллээс энэ хуудсанд {count}-г харуулж байна</p>
    </div>
}
type PaginationProps = {
    total: number;
    count: number;
    size: number;
    current: number;
}
export default Pagination;