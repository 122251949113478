import { FC } from 'react';
import ElementProps from '../../types/ElementProps';
/**
 * link-view
 * @param param0 
 * @returns 
 */
const Dg1051: FC<ElementProps> = ({ item, value }) => {
    return <a href={value.value}>{value.value}</a>;
}
export default Dg1051;