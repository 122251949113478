import { FC } from 'react';
import { getValue } from '../utils/valueUtils';
import ElementProps from '../types/ElementProps';

import Dg1 from './edit/Dg1';
import EditElement from './EditElement';
import ViewElement from './ViewElement';
import WidgetElement from './WidgetElement';

const Element: FC<ElementProps> = ({ properties, item, onChange }) => {
    const type = getValue(properties, 'type');
    if (type == null) {
        console.log('Element type does not exists.', properties, item)
        return <>Element type does not exists.</>
    } else {
        const typeInt = parseInt(type);
        const size = getValue(properties, 'size');
        const className = getValue(properties, 'class');
        properties.class = 'dg-' + typeInt
            + (className ? ' ' + className : '')
            + (size ? ' col-' + size.replace('/', '-') : '')
            + (properties?.id ? ' f' + properties.id : '');
        const Tag = typeInt < 1000 ? EditElement : typeInt < 5000 ? ViewElement : WidgetElement;
        return typeInt === 1 ? <Dg1 properties={properties} item={item} onChange={onChange} />
            : <Tag properties={properties} item={item} type={'Dg' + typeInt} onChange={onChange} />;
    }
}

export default Element;