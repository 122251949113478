import { FC } from 'react';
import { getValue } from '../../utils/valueUtils';

let parentNode: any;
let draggedNode: any;
let rootNode: any;

const Builder: FC<BuilderProps> = ({ root, onChange, renderNode }) => {
    const handleChange = () => {
        onChange(root?.items?.length > 0 ? root.items[0] : null);
    }
    return <Nodes nodes={root?.items} root={root} onChange={handleChange} renderNode={renderNode} />;
}

const Nodes: FC<NodesProps> = ({ nodes, root, onChange, renderNode }) => {

    const onDragOver = (ev: any) => {
        ev.preventDefault();
    }

    const onDrop = (ev: any) => {
        // Давхар тагийн давхар дуудалтыг зогсооно.
        ev.stopPropagation();
        // Чирж байгаа зангилааны дугаарыг авна.
        const id: number = parseInt(ev.dataTransfer.getData("id"));
        if (draggedNode && parentNode) {
            // Чирж байгаа зангилаа байвал
            // эх зангилаа дотроос түүнийг устгаж
            if (rootNode === root) {
                parentNode.items.splice(parentNode.items.indexOf(draggedNode), 1);
            } else {
                draggedNode = JSON.parse(JSON.stringify(draggedNode));
            }
            // шинэ байрлалд оруулна
            nodes.push(draggedNode);
            // өөрчлөлтийг дэлгэцэнд дүрсэлнэ.
            onChange && onChange();
        }
        ev.target.classList.remove('drag-over');
        draggedNode = null;
        parentNode = null;
    }

    const onDragEnter = (ev: any) => {
        ev.target.classList.add('drag-over');
    }

    const onDragLeave = (ev: any) => {
        ev.target.classList.remove('drag-over');
    }
    return <ol onDragOver={onDragOver} onDrop={onDrop} onDragEnter={onDragEnter} onDragLeave={onDragLeave}>
        {nodes?.map((node: any, i) =>
            <Node key={i} node={node} nodes={nodes} root={root} onChange={onChange} renderNode={renderNode} />)}
    </ol>
}
const Node: FC<NodeProps> = ({ node, nodes, root, onChange, renderNode }) => {

    const onDragStart = (ev: any, node: any) => {
        // Давхар тагийн давхар дуудалтыг зогсооно.
        ev.stopPropagation();
        ev.dataTransfer.setData("id", node.id);
        draggedNode = node;
        parentNode = findParentNode(root, node);
        rootNode = root;
    }

    const onDragOver = (ev: any) => {
        ev.preventDefault();
    }

    const onDrop = (ev: any) => {
        // Давхар тагийн давхар дуудалтыг зогсооно.
        ev.stopPropagation();
        if (draggedNode && parentNode) {
            const index = parentNode.id === node.id && nodes.indexOf(node) < nodes.indexOf(draggedNode) ?
                nodes.indexOf(node) : nodes.indexOf(node) + 1;
            if (rootNode === root) {
                parentNode.items.splice(parentNode.items.indexOf(draggedNode), 1);
            } else {
                draggedNode = JSON.parse(JSON.stringify(draggedNode));
            }
            nodes.splice(index, 0, draggedNode);
            onChange && onChange();
        }
        draggedNode = null;
        parentNode = null;
    }
    const type = getValue(node, 'type');
    let className = '';
    if (type === 5002) {
        const size = getValue(node, 'size') ?? '1/4';
        className += ' e' + type + ' col-' + size.replace('/', '-');
    } else {
        className += ' e' + type;
    }
    return <li key={node} draggable onDragStart={(e) => onDragStart(e, node)}
        onDragOver={onDragOver} onDrop={onDrop} className={className}>
        <>
            {renderNode(node)}
            {node.items ? <Nodes root={root} nodes={node.items} onChange={onChange} renderNode={renderNode} /> : null}
        </>
    </li>
}
const findParentNode: any = (root: any, node: any) => {

    const find: any = (parent: any, child: any) => {
        if (node === child) {
            return parent;
        }
        if (child.items) {
            for (const item of child.items) {
                const result = find(child, item);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    };

    return find(null, root);
}
type BuilderProps = {
    root: any;
    onChange?: any;
    renderNode: (node: any) => {};
};
type NodesProps = {
    nodes: any[];
    root: any;
    onChange: any;
    renderNode: (node: any) => {};
};
type NodeProps = {
    node: any;
    nodes: any[];
    root: any;
    onChange: any;
    renderNode: (node: any) => {};
};
export default Builder;