import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import MediaService from '../services/MediaService';
import MediaFolder from '../types/MediaFolder';
import MediaItem from '../types/MediaItem';
import { AuthContext, SiteContext } from '../utils/context';

const Media = () => {
    const { site } = useContext(SiteContext);
    const { token } = useContext(AuthContext);
    const path = useParams();
    const activePath = path['*'] ? path['*'].replace('.html', '') + '/' : '/';
    const [folders, setFolders] = useState([] as MediaFolder[]);
    const [images, setImages] = useState([] as MediaItem[]);
    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(false);
    var prefix = site.param?.media ? site.param.media : 'upload';
    var prefix2 = site.param?.media ? site.param.media : 'image/150t150';
    var getType = (fileName: string) => {
        var ext = fileName.substring(fileName.lastIndexOf('.') + 1);
        if (ext === 'png' || ext === 'jpg' || ext === 'jpeg' || ext === 'gif') {
            return 'img';
        }
        return ext;
    };
    let link = '/media';
    var pathParts: any = activePath.split('/').map((part: string) => {
        link += '/' + part;
        return { folder: part, link: link + '.html' }
    });;
    const parentPath = activePath.split('/').length > 1 ? activePath.split('/').slice(0, -1).join('/') : null;
    // pathParts = [{ folder: 'Медиа', link: link + '.html' }];
    useEffect(() => {
        new MediaService(token).dir(prefix + activePath)
            .then(response => response.json()).then(response => {
                let folders: any = [];
                let images: any = [];
                response.forEach((val: string) => {
                    if (val.substring(val.length - 1) === "/") {
                        var name = val.substring(0, val.length - 1);
                        folders.push({
                            name: name,
                            url: 'media' + activePath + name + '.html'
                        });
                    } else {
                        images.push({
                            name: decodeURI(val),
                            url: prefix + activePath + val,
                            thumb: prefix2 + activePath + val,
                            type: getType(val)
                        });
                    }
                });
                setFolders(folders);
                setImages(images);
            });
    }, [activePath]);
    var absUrl = (url: string) => {
        if (url.substr(0, 7) !== 'http://' &&
            url.substr(0, 8) !== 'https://' &&
            url.substr(0, 2) !== '//') {
            if (url.charAt(0) !== '/') {
                url = '/' + url;
            }
            return window.location.origin + url;
        }
        return url;
    };
    const imageClick = (image: MediaItem) => {
        if (window === window.top) {
            window.open(image.url, '_blank')?.focus();
        } else {
            var el = window.top?.document.getElementById('modalMediaUrl');
            const parentWindow: any = window.parent;
            if (el != null) {
                var scope = parentWindow.angular.element(el).scope();
                scope.$ctrl.mediaUrl = image.url;
                scope.$apply();
            } else {
                var content;
                switch (image.type) {
                    case 'img':
                        content = '<img src="' + image.url + '" width="800">';
                        break;
                    case 'svg':
                        content = '<img src="' + image.url + '" width="100">';
                        break;
                    case 'doc':
                    case 'docx':
                    case 'xls':
                    case 'xlsx':
                    case 'ppt':
                    case 'pptx':
                    case 'pdf':
                    case 'txt':
                        content = '<iframe src="https://docs.google.com/gview?url=' + absUrl(image.url) + '&embedded=true" width="100%" height="400px"></iframe>';
                        break;
                    default:
                        content = '<a href="' + image.url + '">';
                        content += '<img src="img/file-types/' + image.type + '.svg" width="100">';
                        content += '</a>';
                        break;
                }
                parentWindow.tinyMCE.activeEditor.insertContent(content);
                parentWindow.tinyMCE.activeEditor.windowManager.close(window);
            }
        }
    };
    const add = (e: any) => {
        const files = e.target.files;
        var uploadFile = function (i: number) {
            if (files.length > i) {
                var file = files[i];
                var uploadPath = prefix + activePath + file.name;
                setLoading(true);
                new MediaService(token).upload(uploadPath, file)
                    .then(response => response.json())
                    .then((data: any) => {
                        images.unshift({
                            name: data.title,
                            url: prefix + activePath + data.title,
                            thumb: prefix2 + activePath + data.title,
                            type: getType(data.title)
                        });
                        setLoading(false);
                        uploadFile(i + 1);
                    }, function () {
                        setLoading(false);
                    });
            }
        }
        uploadFile(0);
    };
    const edit = (image: MediaItem) => {
        if (image.type === 'img') {
            var path = prefix + activePath + image.name;
            window.open('/image-editor.html?image=' + path, '_blank')?.focus();
        } else {
            alert('Уучлаарай, засварлах боломжгүй файл байна.');
        }
    };
    const remove = (image: MediaItem) => {
        if (window.confirm('Та "' + image.name + '" зургийг устгаж байгаадаа итгэлтэй байна уу?')) {
            var uploadPath = prefix + activePath + image.name;
            fetch(uploadPath, {
                method: 'DELETE'
            }).then(() => {
                images.splice(images.indexOf(image), 1);
            });
        }
    };
    const mkdir = () => {
        var title = window.prompt('Хавтасны нэр');
        if (title) {
            title = title.trim();
            if (title.length > 0) {
                new MediaService(token).mkdir(prefix + activePath, title).then(data => data.json()).then((value: any) => {
                    if (value.status) {
                        folders.unshift({
                            name: value.title,
                            url: 'media' + activePath + value.title + '.html'
                        });
                    }
                });
            }
        }
    };
    const rmdir = () => {
        if (window.confirm('Та энэ хавтсыг устгаж байгаадаа итгэлтэй байна уу?')) {
            new MediaService(token).rmdir(prefix + activePath)
                .then(data => data.json()).then(() => {
                    window.history.back();
                });
        }
    };
    const rename = () => {
        var title = window.prompt('Хавтасны шинэ нэр') || '';
        if (title && title.length > 0) {
            new MediaService(token).rename(prefix + activePath, title)
                .then(data => data.json()).then(data => data.json()).then(value => {
                    // TODO redirect to renamed folder
                    window.history.back();
                });
        }
    };
    return <div className="media-view">
        <div className="page-head">
            <h2><i className="fa fa-picture-o fa-fw"></i> Медиа</h2>
            {pathParts.map((part: any) => <Link key={part.link} to={part.link}>{part.folder}</Link>)}
            {activePath !== '/' ? <div className="btn-group">
                <button className="btn btn-default btn-xs" onClick={() => { rename() }}><i className="fa fa-edit"></i></button>
                <button className="btn btn-default btn-xs" onClick={() => { rmdir() }}><i className="fa fa-trash"></i></button>
            </div> : null}
            <input value={query} placeholder="хайх..." onChange={e => setQuery(e.target.value)} />
        </div>
        <div className="folders">
            {parentPath ? <a className="up" href={parentPath}>Хавтас нэмэх</a> : null}
            <div className="add" onClick={mkdir}>Хавтас нэмэх</div>
            {folders.sort((a: MediaFolder, b: MediaFolder) => a.name > b.name ? 1 : -1).map((folder, i) =>
                <a key={folder.name} className="folder" href={folder.url}>
                    {folder.name}
                </a>)}
        </div>
        <div className="images">
            <div className="add">
                <input type="file" name="file" onChange={add} multiple />
            </div>
            {images.sort((a: MediaItem, b: MediaItem) => a.name > b.name ? 1 : -1).map((image, i) => <div key={image.name} className="item">
                <img src={image.type == 'img' ? image.thumb : image.type == 'svg' ? image.url : '/img/file-types/' + image.type + '.svg'}
                    alt={image.name} className="img-responsive" onClick={() => { imageClick(image) }} />
                <h4>{image.name}</h4>
                <div className="btn-group">
                    <div className="btn btn-primary btn-sm" onClick={() => { edit(image) }}>
                        <i className="fa fa-edit"></i>
                    </div>
                    <button className="btn btn-danger btn-sm" onClick={() => { remove(image) }}><i className="fa fa-trash"></i></button>
                </div>
            </div>)}
        </div>
    </div>
}
export default Media;