import { FC, useContext, useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import ItemService from '../../services/ItemService';
import { AuthContext, SiteContext } from '../../utils/context';
import { handleRequestError } from '../../utils/responseUtils';
import { collectFields, getCategory, getItemLayout, getMultiParents, getValue, slugify } from '../../utils/valueUtils';

const ExcelModal: FC<ExcelModalProps> = ({ title, category, pntId, item, visible, onClose }) => {
    const { site } = useContext(SiteContext);
    const { token } = useContext(AuthContext);
    const [total, setTotal] = useState(0);
    const [saved, setSaved] = useState(0);
    const [titles, setTitles] = useState([] as any[]);
    const [fields, setFields] = useState([] as any[]);
    const [data, setData] = useState([] as any[]);
    const [labels, setLabels] = useState({} as any);
    const [mapping, setMapping] = useState({} as any);

    var result: any = [];
    var allData: any = [];
    const removeEmptyValues = (item: any) => {
        for (var i in item) {
            if (item[i] === null || item[i] === false || item[i] === '') {
                // NULL эсвэл хоосон тэмдэгт утгатай шинж чанар бол устгана
                delete item[i];
            } else if (Array.isArray(item[i])) {
                // Массив бол
                item[i].forEach((prop: any, j: number) => {
                    // Элемент бүр дээр цэвэрлэх үйлдлийг дуудна
                    if (typeof prop === 'object' && !Array.isArray(prop)) {
                        removeEmptyValues(prop);
                        if (Object.keys(prop).length === 0 ||
                            ('field' in prop && !('id' in prop) && !('value' in prop))) {
                            // Массивын элемент хоосон обьект бол устгана
                            delete item[i][j];
                        }
                    }
                });
                // Массивын хоосон элементүүдийг устгана
                item[i] = item[i].filter((prop: any) => { return prop != null });
                if (item[i].length === 0) {
                    // Хоосон массив утгатай шинж чанар бол устгана
                    delete item[i];
                }
            } else if (typeof item[i] === 'object') {
                // Обьект бол
                removeEmptyValues(item[i]);
                if (Object.keys(item[i]).length === 0) {
                    delete item[i];
                }
            }
        }
    }
    const optimizeInsertData = (data: any) => {
        data.name = data.name ? slugify(data.name) : '';
        var item = { ...data };
        removeEmptyValues(item);
        if (item.category > 0) {
            item.categories = getMultiParents(site, getCategory(site, item.category));
        }
        return item;
    }
    // excel файлыг уншиж, баганы жагсаалтыг авах функц
    var read = (file: any) => {
        var reader = new FileReader();
        reader.onload = (e: any) => {
            const mapping: any = {};
            var data = e.target.result;
            var workbook = XLSX.read(data, { type: 'array' });
            allData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { defval: null });
            setTotal(allData.length);
            if (allData.length > 0) {
                // Эхинй мөрийг хүснэгтийн гарчиг болгоно
                setTitles(Object.keys(allData[0]).filter(function (title) {
                    return title.substr(0, 7) !== '__EMPTY';
                }));
                // Талбар болон баганы нэр ижил бол автоматаар харгалзуулна
                fields.forEach(function (field) {
                    var matches = titles.filter(function (title) {
                        return title.toUpperCase().trim() === labels[field.id].toUpperCase().trim();
                    });
                    if (matches.length > 0) {
                        mapping[field.id] = matches[0];
                    }
                });
                for (var i = 0; i < 10 && i < allData.length; i++) {
                    data.push(allData[i]);
                }
            }
            setMapping(mapping);
        }
        reader.readAsArrayBuffer(file);
    }
    // файл сонгогдсон үед дуудагдана
    const change = (input: any) => {
        if (input.files.length > 0) {
            read(input.files[0]);
        } else {
            setData([]);
            setTitles([]);
        }
    };
    // утгын төрөл хувиргалт хийнэ
    var convertType = (field: any, value: any) => {
        switch (field.type) {
            case 5:
                value = parseInt(value);
                break;
            case 7:
                value = parseFloat(value);
                break;
            case 3: // date
            case 9:
            case 1:
                value = value.toString();
                break;
        }
        return value;
    }
    var insert = (item: any) => {
        new ItemService(token).add(optimizeInsertData(item)).then((data: any) => data.json()).then((value: any) => {
            setSaved(saved + 1)
            if (value.id > 0) {
                item.id = value.id;
                result.push(item);
                if (saved < allData.length) {
                    save();
                } else {
                    onClose({ val: result });
                }
            } else {
                alert(saved + "-р мөрийг нэмэхэд алдаа гарлаа")
            }
        }, function (e) {
            onClose({ val: result });
            handleRequestError(e)
        });
    };
    // excel файлын нэг мөрийг мэдээлэл рүү хөрвүүлнэ
    var rowToItem = (row: any) => {
        var item: any = {
            category: category.id,
            status: 30,
            values: [] as any[]
        };
        fields.forEach((field: any) => {
            if (field.id in mapping && mapping[field.id] !== '') {
                var value = row[mapping[field.id]];
                if (value) {
                    value = convertType(field, value);
                    if (typeof field.id === 'number') {
                        item.values.push({ field: field.id, value: value });
                    } else {
                        item[field.id] = value;
                    }
                }
            }
        });
        return item;
    };
    // хадгалах үйлдэл хийнэ
    var save = () => {
        // Нэг мөрийг мэдээлэл болгоно
        var item: any = rowToItem(allData[saved]);
        if (pntId > 0) {
            item.parent = pntId;
        }
        insert(item);
    };
    // харилцах цонхыг нээхэд дуудагдана
    useEffect(() => {
        setFields(collectFields(getItemLayout(site, category, 'form').items));
        const labels: any = {};
        fields.forEach((field) => {
            return labels[field.id] = getValue(field, 'title');
        });
        setLabels(labels);
    }, [category, pntId]);

    const ok = () => {
        if (allData.length > 0) {
            setSaved(0);
            save();
        }
    };

    const cancel = () => {
        onClose({ val: [] });
    };
    return visible ? <div className="modal-wrapper">
        <div className='modal'>
            <h3>{title}</h3>
            <form className="form-horizontal" role="form" onSubmit={ok}>
                <div className="form-group">
                    <label htmlFor="excel">Excel файл</label>
                    <input type="file" id="excel" name="excel" title="Excel файл оруулах"
                        onChange={change} />
                </div>
                <div className="mapping">
                    {fields.map((field: any, i: number) => <span key={i}>
                        <label htmlFor="ff{{field.id}}">{labels[field.id]}</label>
                        <select id="ff{{field.id}}" ng-model="mapping[field.id]">
                            <option value="">Багана сонгох</option>
                            <option ng-repeat="title in titles">{title}</option>
                        </select>
                    </span>)}
                </div>
                <div className="table-wrapper">
                    <table className="table table-striped table-bordered table-hover">
                        {titles.map((title: string) => <th key={title}>
                            {title}
                        </th>)}
                        {data.map((row: any) => <tr key={row}>
                            {row.map((value: string) => <td>{value}</td>)}
                        </tr>)}
                    </table>
                </div>
                <div className="modal-footer">
                    <label>{saved}/{total}</label>
                    <button type="submit" className="btn btn-primary"><i className="fa fa-check"></i> OK</button>
                </div>
            </form>
        </div>
    </div> : null;
}
type ExcelModalProps = {
    title: string;
    category: any;
    pntId: number;
    visible: boolean;
    onClose: any;
    item: any;
};
export default ExcelModal;