import { useEffect, FC, useContext, useState } from 'react';
import ElementProps from '../../types/ElementProps';
import { SiteContext } from '../../utils/context';
import { reduceValues, slugify } from '../../utils/valueUtils';
import MediaModal from '../modal/MediaModal';
// image-edit
const Dg9: FC<ElementProps> = ({ properties, item, onChange, value }) => {
	const { site } = useContext(SiteContext);
	const { multi } = reduceValues(properties);
	const [visible, setVisible] = useState(false);
	var activePath = '/';
	useEffect(() => {
	}, []);
	const handleChange = (event: any) => {
		const value = event.target.value;
		const uploadFile = (j: number) => {
			if (event.target.files.length > j) {
				var file = event.target.files[j];
				var fd = new FormData();
				let prefix = site.param.media ? site.param.media : '/upload';
				let prefix2 = site.param.media ? site.param.media : '/images/150t150';
				var uploadPath = prefix + activePath + slugify(file.name);
				fd.append("file", file);
				fetch(uploadPath,
					{
						method: "POST",
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json'
						}, body: fd
					}).then(function () {
						if (j === 0) {
							value.value = uploadPath;
						} else {
							var values = item.values.filter((value: any) =>
								value.value == null && value.field === properties.id);
							values[0].value = uploadPath;
							onChange(uploadPath);
						}
						uploadFile(j + 1);
					});
			}
		}
		uploadFile(0);
	};
	const getImage = () => {
		setVisible(true);
	};
	const modalClose = (url: string) => {
		value.value = url;
		onChange();
	}
	const remove = () => {
		value.value = null;
		onChange();
	};
	return <div className="item">
		{value.value && <img src={value.value} alt={value.value} className="img-responsive" />}
		<input type="file" title="Шинэ зураг оруулах" onChange={handleChange} multiple={multi} />
		<i className="fa fa-upload"></i>
		<div className="btn-group">
			<a href="" className="btn btn-primary btn" onClick={() => { getImage() }} title="Өмнө нь оруулсан зургаас ашиглах"><i
				className="fa fa-cloud-upload"></i></a>
			<a href="" ng-if="value.value" className="btn btn-danger btn" onClick={() => { remove() }} title="Зургийг мэдээнээс хасах"><i
				className="fa fa-trash"></i></a>
		</div>
		<MediaModal visible={visible} src={value.value} onClose={modalClose} />
	</div>;
}
export default Dg9;