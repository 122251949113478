import { FC } from 'react';
import ElementProps from '../../types/ElementProps';
import Element from '../Element';
// Мөр
const Dg5001: FC<ElementProps> = ({ properties, item }) => {
    return <div className={properties.class}>
        {properties.items?.map((element: any, i: number) =>
            <Element key={i} properties={element} item={item} />)}
    </div>
}
export default Dg5001;