import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import MeService from '../services/MeService';
import { AuthContext } from '../utils/context';

const Logout = () => {
    const { token, setToken } = useContext(AuthContext);
    const navigate = useNavigate();
    new MeService(token).signOut().then(response => {
        setToken('');
        navigate('/');
    });
    return <></>;
}
export default Logout;