import { FC } from 'react';
import ElementProps from '../../types/ElementProps';
import { reduceValues } from '../../utils/valueUtils';
/**
 * memo-edit
 * @param param0 
 * @returns 
 */
const Dg10: FC<ElementProps> = ({ properties, onChange, value }) => {
	const { title, className, required, readonly } = reduceValues(properties);
	return <textarea placeholder={title} required={required} readOnly={readonly}
		className={className} onChange={e => onChange(e.target.value)}>{value.value}</textarea>;
}
export default Dg10;