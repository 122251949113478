import { FC } from 'react';
import ElementProps from '../../types/ElementProps';
/**
 * cart-view
 * @param param0 
 * @returns 
 */
const Dg1100: FC<ElementProps> = ({ item, value }) => {
    return <>{value.value}</>;
}
export default Dg1100;