import { useState, useEffect, useContext } from 'react';
import { Outlet } from "react-router-dom";
import { getSiteLayouts } from '../utils/valueUtils';

import Element from '../components/Element';
import { AuthContext, SiteContext } from '../utils/context';

const Root = () => {
    const [layouts, setLayouts] = useState({} as any);
    const user = useContext(AuthContext);
    const { site } = useContext(SiteContext);
    useEffect(() => {
        setLayouts(getSiteLayouts(site));
    }, [site, user]);
    return layouts ?
        <>
            {window.self === window.top && layouts.sysHeader && <Element properties={layouts.sysHeader} />}
            {window.self === window.top && layouts.header && <Element properties={layouts.header} />}
            <Outlet />
            {window.self === window.top && layouts.footer && <Element properties={layouts.footer} />}
            {window.self === window.top && layouts.sysFooter && <Element properties={layouts.sysFooter} />}
        </> : <div className="loading"></div>
}
export default Root;