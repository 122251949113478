import Service from './Service';

/**
 * Файл удирдах сервис
 */
export default class MediaService extends Service {
    postfix: string;
    constructor(token: string) {
        super('', token);
        this.postfix = 'dir.json';
    }
    /**
     * Өгөгдсөн замд байгаа файл болон хавтсын жагсаалтыг авна
     * @param {string} path Зам
     * @returns Файл болон хавтсын жагсаалт
     */
    dir = (path: string) => fetch(this.alias + '/' + path + this.postfix, {
        method: "GET",
        headers: super.getHeaders()
    });
    /**
     * Өгөгдсөн зам дээр хавтас үүсгэнэ
     * @param {string} path Зам
     * @param {string} name Хавтасны нэр
     * @returns Төлөв
     */
    mkdir = (path: string, name: string) => fetch(this.alias + '/' + path + this.postfix, {
        method: "POST",
        headers: super.getHeaders(),
        body: JSON.stringify({ title: name })
    });
    /**
     * Өгөгдсөн хавтасны нэрийг солино
     * @param {string} path Зам
     * @param {string} name Шинэ нэр
     * @returns Төлөв
     */
    rename = (path: string, name: string) => fetch(this.alias + '/' + path + this.postfix, {
        method: "PUT",
        headers: super.getHeaders(),
        body: JSON.stringify({ title: name })
    });
    /**
     * Өгөгдсөн хавтсыг устгана
     * @param {string} path Зам
     * @returns Төлөв
     */
    rmdir = (path: string) => fetch(this.alias + '/' + path + this.postfix, {
        method: "DELETE",
        headers: super.getHeaders()
    });

    upload = (path: string, file: any) => {
        var fd = new FormData();
        fd.append("file", file);
        return fetch(this.alias + '/' + path, {
            method: 'POST',
            //headers: { 'Content-Type': undefined },
            body: fd
        });
    }
}