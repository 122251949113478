import React, { FC } from 'react';
import ElementProps from '../types/ElementProps';

import Dg1000 from './view/Dg1000';
import Dg1001 from './view/Dg1001';
import Dg1002 from './view/Dg1002';
import Dg1003 from './view/Dg1003';
import Dg1050 from './view/Dg1050';
import Dg1051 from './view/Dg1051';
import Dg1100 from './view/Dg1100';
import Dg1101 from './view/Dg1101';
import Dg1200 from './view/Dg1200';

const ViewElement: FC<ElementProps> = (props) => {
    const elements: any = {
        Dg1000: Dg1000,
        Dg1001: Dg1001,
        Dg1002: Dg1002,
        Dg1003: Dg1003,
        Dg1050: Dg1050,
        Dg1051: Dg1051,
        Dg1100: Dg1100,
        Dg1101: Dg1101,
        Dg1200: Dg1200
    };
    const Tag = elements[props.type ?? ''];

    return Tag ? props.item.values && props.item.values.filter((value: any) => value.field === props.properties.id)
        .map((value: any, i: number) => <Tag key={i} properties={props.properties} value={value} item={props.item} />)
        : <div>Unsuppoerted Element: {props.type}</div>;
}

export default ViewElement;