
import { FC } from 'react';
import ElementProps from '../../types/ElementProps';
import { getValue, reduceValues } from '../../utils/valueUtils';
// list-edit
const Dg3: FC<ElementProps> = ({ properties, item, onChange, value }) => {
	const { title, valueType } = reduceValues(properties);
	let options = getValue(properties, 'values');
	return <>
		{valueType != 5 && <select className="class"
			ng-repeat="value in item.values | filter: {field:properties.id}"
			value={properties.value}
			ng-options="v as v for (k, v) in options">
			<option value="">---{title}---</option>
		</select>}
		{valueType == 5 && <select className="class" convert-to-number
			ng-repeat="value in item.values | filter: {field:properties.id}"
			value={properties.value}
			ng-options="v as v for (k, v) in options">
			<option value="">---{title}---</option>
		</select>}
		{false && <input type="checkbox" ng-true-value="1" ng-false-value="0" className="attr.class"
			ng-repeat="value in item.values | filter: {field:properties.id}" value={properties.value} />}
		{false && <div ng-if="false" ng-repeat="value in item.values | filter: {field:properties.id}" className="radio-group">
			<label ng-repeat="(k, v) in options">
				<input type="radio" value={options.value} /> {/*v*/}
			</label>
		</div>}
	</>;
}
export default Dg3;