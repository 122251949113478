import { useState, useEffect, FC, useContext } from 'react';
import { useParams } from 'react-router-dom';
import ElementProps from '../../types/ElementProps';
import { SiteContext } from '../../utils/context';
import { getFields, getValue, reduceValues } from '../../utils/valueUtils';
// field-edit
// системийн талбар. Утгаа шууд item[properties.id] руу хадгална
const Dg14: FC<ElementProps> = ({ properties, item, onChange, value }) => {
    const { site } = useContext(SiteContext);
    const { nodeId } = useParams();
    const [options, setOptions] = useState([] as { id: number | string, title: string }[]);
    const { title, required, readonly } = reduceValues(properties);
    useEffect(() => {
        var catId = nodeId ? parseInt(nodeId) : item.category;
        var pairs = getValue(properties, 'pairs');
        if (pairs === null) {
            setOptions(getFields(site, catId).map(function (field) {
                return { id: field.id, title: getValue(field, 'title') };
            }));
        } else {
            setOptions(Object.keys(pairs).map(function (key) {
                return { id: key, title: pairs[key] };
            }));
        }
    }, []);
    const handleChange = (event: any) => {
        onChange(event.target.value);
    };
    return <select required={required} disabled={readonly}
        value={value.value} onChange={handleChange}>
        <option value="">---{title}---</option>
        {options.map((option: any, i) => <option key={i} value={option.id}>{option.title}</option>)}
    </select>
}
export default Dg14;