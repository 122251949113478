import { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MeService from '../services/MeService';
import { AuthContext, UserContext } from '../utils/context';

const Login = () => {
    const { token, setToken } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const handleLogin = (event: any) => {
        event.preventDefault();
        new MeService(token).signIn(email, password).then(response => response.json()).then(data => {
            setToken(data.access_token);
        });
    };
    useEffect(() => {
        if (user.id > 0) {
            navigate('/');
        }
    }, [user.id])
    return <div className="login-box">
        <h2></h2>
        <form role="form" onSubmit={handleLogin} className="box">
            <div className="form-group">
                <label>Цахим шуудан</label>
                <input type="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <div className="form-group">
                <label>Нууц үг</label>
                <input type="password" className="form-control" value={password} onChange={e => setPassword(e.target.value)} />
            </div>
            <div className="form-actions">
                <input type="checkbox" className="uniform" value="" /><label>Сануулах</label>
                <button type="submit" className="btn btn-primary"><i className="fa fa-unlock"></i> Нэвтрэх</button>
            </div>
            <p>
                <Link to="/password.html">Нууц үгээ мартсан уу?</Link>
            </p>
            <p>
                <Link to="/register.html">Шинээр бүртгүүлэх</Link>
            </p>
        </form>
    </div>
}
export default Login;