import { useState, FC } from 'react';
import ElementProps from '../../types/ElementProps';
import { getValue } from '../../utils/valueUtils';
// pair-edit
const Dg8: FC<ElementProps> = ({ properties, item, onChange }) => {
	const [value, setValue] = useState(item[properties.id]);
	const pairs = getValue(properties, 'pairs');
	const options = [];
	for (var key in pairs) {
		options.push(<option key={key} value={key}>{pairs[key]}</option>);
	}
	const title = getValue(properties, 'title');
	const handleChange = (event: any) => {
		item[properties.id] = event.target.value;
		onChange();
		setValue(event.target.value);
	};
	return <select value={value} onChange={handleChange}>
		<option value="">---{title}---</option>
		{options}
	</select>
}
export default Dg8;