import { FC, useContext, useState } from 'react';
import ElementProps from '../../types/ElementProps';
import MediaModal from '../modal/MediaModal';
import { SiteContext } from '../../utils/context';
import { reduceValues } from '../../utils/valueUtils';
/**
 * file-edit
 * @param param0 
 * @returns 
 */
const Dg18: FC<ElementProps> = ({ properties, item, onChange, value }) => {
	const { site } = useContext(SiteContext);
	const [visible, setVisible] = useState(false);
	const { multi } = reduceValues(properties);
	var activePath = '/';
	const upload = function (input: any) {
		var fldValId = input.name;
		var uploadFile = function (j: number) {
			if (input.files.length > j) {
				var file = input.files[j];
				var fd = new FormData();
				var prefix = site.param.media ? site.param.media : '/upload';
				var uploadPath = prefix + activePath + file.name;
				fd.append("file", file);
				fetch(uploadPath,
					{
						method: "POST",
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json'
						}, body: fd
					}).then(function () {
						item.fields[properties.id][fldValId] = prefix + activePath + file.name;
						if (properties.multi) {
							fldValId = onChange();
							uploadFile(j + 1);
						}
					});
			}
		}
		uploadFile(0);
	};
	const getFile = function () {
		setVisible(true);
	};
	const handleClose = (url: any) => {
		value.value = url;
		onChange();
	};
	const remove = () => {
		value.value = null;
		onChange();
	};
	return <div className="item">
		{value.value && <img src={value.value} alt={value.value} className="img-responsive" />}
		{multi ? <input type="file" title="Шинэ зураг оруулах"
			onChange={() => { upload(this) }} multiple />
			: <input type="file" title="Шинэ зураг оруулах"
				onChange={() => { upload(this) }} />}
		<i className="fa fa-upload"></i>
		<div className="btn-group">
			<a href="" className="btn btn-primary btn" onClick={() => { getFile() }} title="Өмнө нь оруулсан зургаас ашиглах"><i
				className="fa fa-cloud-upload"></i></a>
			<a href="" ng-if="value.value" className="btn btn-danger btn" onClick={() => { remove() }} title="Зургийг мэдээнээс хасах"><i
				className="fa fa-trash"></i></a>
		</div>
		<MediaModal src={value.value} onClose={handleClose} visible={visible} />
	</div>;
}
export default Dg18;