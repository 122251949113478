import { useContext } from 'react';
import { useParams, redirect } from 'react-router-dom';
import ItemElement from './ItemElement';
import { SiteContext } from '../utils/context';
import { getValue } from '../utils/valueUtils';

function Page() {
  const { itemId, parentId, layout, categoryId } = useParams();
  const { site } = useContext(SiteContext);
  let item: any = { status: 30 };
  if (!itemId && !parentId && !layout && !categoryId) {
    item.id = getValue(site, 51);
  }
  if (itemId) {
    item.id = parseInt(itemId);
  }
  if (parentId) {
    item.parent = parseInt(parentId);
  }
  let layoutName = layout ?? 'full';
  let className = layoutName + ' page' + item.id;
  let title = null;
  let categoryTitle = null;
  if (categoryId) {
    if (categoryId === '0') {
      item = site;
    } else {
      item.category = parseInt(categoryId);
    }
  };
  const handleCancel = (item: any) => {
    if (item.parent > 0) {
      redirect("items/" + item.parent + "/categories/" + item.category + "/table.html");
    } else {
      redirect("categories/" + item.category + "/table.html");
    }
  };
  const handleSave = (item: any) => {
    if (item.parent > 0) {
      redirect("items/" + item.parent + "/categories/" + item.category + "/table.html");
    } else {
      redirect("categories/" + item.category + "/table.html");
    }
  };
  return <div className={className}>
    <h2>{categoryTitle}: {title}</h2>
    <ItemElement item={item} layoutName={layoutName} onSave={handleSave} onCancel={handleCancel} />
  </div>;
}
export default Page;