/**
 * Сервертэй харьцах ерөнхий сервис
 */
export default class Service {
    alias: string;
    token: string;
    constructor(alias: string, token: string) {
        this.alias = window.location.protocol + '//' + window.location.hostname + '/' + alias;
        this.token = token;
    }
    /**
     * Мэдээллийн жагсаалт авна
     * @param {*} params Мэдээллийг хайлт, шүүлтийн тохиргоо
     * @returns Мэдээллийн жагсаалт
     */
    query(params: any = null) {
        var url = new URL(this.alias);
        if (params != null) {
            Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
        }
        return fetch(url, {
            method: "GET",
            headers: this.getHeaders()
        });
    }
    /**
     * Өгөгдсөн дугаартай мэдээллийг авна
     * @param {number} id Авах мэдээллийн дугаар
     * @returns Мэдээлэл
     */
    get = (id: number) => fetch(this.alias + '/' + id, {
        method: "GET",
        headers: this.getHeaders()
    });
    /**
     * Мэдээлэл нэмнэ
     * @param {object} item Мэдээлэл
     * @returns 
     */
    add = (item: any) => fetch(this.alias, {
        method: "POST",
        headers: this.getHeaders(),
        body: JSON.stringify(item)
    });
    /**
     * Мэдээлэл засна
     * @param {object} item Мэдээлэл
     * @returns 
     */
    edit = (item: any) => fetch(this.alias + '/' + item.id, {
        method: "PUT",
        headers: this.getHeaders(),
        body: JSON.stringify(item)
    });
    /**
     * Мэдээлэл устгана
     * @param {object} item Мэдээлэл
     * @returns 
     */
    remove = (item: any) => fetch(this.alias + '/' + item.id, {
        method: "DELETE",
        headers: this.getHeaders()
    });
    /**
     * Үндсэн HTTP header тохиргоог авна
     * @returns Brearer токентой json форматаар өгөгдөл солилцох тохиргоо
     */
    getHeaders() {
        var headers: any = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        if (this.token && this.token !== '') {
            headers.Authorization = 'Bearer ' + this.token;
        }
        return headers;
    }
}