import { useState, FC, useContext } from 'react';
import ElementProps from '../../types/ElementProps';
import { getValue, collectLayout } from '../../utils/valueUtils';
import Modal from '../modal/Modal';
import { SiteContext } from '../../utils/context';
import Builder from './Builder';
// layout-edit
type BAProps = {
    After: typeof After
}
const Dg41: FC<ElementProps> & BAProps = ({ properties, item, onChange, value }) => {
    const { site } = useContext(SiteContext);
    const getLayout = (fieldId: any) => {
        var value = getValue(properties, fieldId);
        if (value !== null) {
            value = getValue(site.default, value);
            if (value !== null) {
                return collectLayout(value, 'form');
            }
        }
    };
    let itemLayouts = getLayout('item');
    let fieldLayouts = getLayout('field');
    return value && <Builder root={{ items: value.value ? [value.value] : [] }} onChange={onChange}
        renderNode={(node: any) => <ElementTmpl node={node} fieldLayouts={fieldLayouts} itemLayouts={itemLayouts} />} />;
}
const ElementTmpl: FC<ElementTmplProps> = ({ node, fieldLayouts, itemLayouts }) => {
    const element = node;
    const title = getValue(element, 'title');
    const type = getValue(element, 'type');
    const [modalValue, setModalValue] = useState({} as any);

    const showDetails = function () {
        var type = getValue(element, 'type');
        type = type === null ? 0 : type;
        setModalValue({
            item: element,
            layout: getValue(type < 5000 ? fieldLayouts[type] : itemLayouts[type], 'layout'),
            title: type < 5000 ? "№ " + element.id : title ? title : type,
            onClose: (item: any) => {
                if (item) {
                    let element = JSON.parse(getValue(item, 'desc'));
                }
                setModalValue({});
            }
        });
    };
    const reset = () => {
        if (window.confirm("Анхны хэлбэрт оруулж байгаадаа итгэлтэй бэйна уу?")) {

        }
    };
    const incWidth = () => { }
    const decWidth = () => { }
    const onShowJson = () => { }
    const onRemove = () => { }
    return <div className="tree-node">
        <div className="tree-handle"><span className="fa fa-arrows"></span></div>
        {title || type ? <a className="title" onClick={showDetails}>
            {title || type}
        </a> : null}
        <div className="tools">
            {type === 5002 ? <a className="decrease" onClick={decWidth}>
                <span className="fa fa-compress" title="Нарийсгах"></span>
            </a> : null}
            {type === 5002 ? <a className="increase" onClick={incWidth}>
                <span className="fa fa-expand" title="Өргөсгөх"></span>
            </a> : null}
            {type !== 5001 && type !== 5002 ? <a className="reset" onClick={reset}>
                <span className="fa fa-refresh" title="Анхны хэлбэрт оруулах"></span>
            </a> : null}
            <a className="json" onClick={onShowJson}>
                <span className="fa fa-file-code-o" title="JSON"></span>
            </a>
            <a className="remove" onClick={onRemove}>
                <span className="fa fa-times" title="Устгах"></span>
            </a>
        </div>
        <Modal layout={modalValue.layout} item={modalValue.item}
            title={modalValue.title} visible={modalValue.item != null}
            onClose={modalValue.onClose} />
    </div>;
}
type ElementTmplProps = {
    node: any;
    fieldLayouts: any[];
    itemLayouts: any[];
};
const After: FC<ElementProps> = ({ properties }) => {
    const { site } = useContext(SiteContext);
    const [activeTab, setActiveTab] = useState(0);
    const getLayout = (fieldId: any) => {
        var value = getValue(properties, fieldId);
        if (value !== null) {
            value = getValue(site.default, value);
            if (value !== null) {
                return collectLayout(value, 'form');
            }
        }
    };
    const getItems = (layouts: any) => Object.keys(layouts).map((key: any) => {
        var layout = layouts[key];
        var result: any = {
            values: [
                { field: 'type', value: layout.id },
                { field: 'title', value: getValue(layout, 'title') }
            ]
        }
        if (getValue(layout, 'contain') != null || getValue(layout, 'exclude') != null) {
            result.items = [];
        }
        return result;
    });
    const itemLayouts = getLayout('item');
    const fieldLayouts = getLayout('field');
    const [widgets, setWidgets] = useState({ items: getItems(itemLayouts) });
    const [fields, setFields] = useState({ items: getItems(fieldLayouts) });
    return <div className="tabs">
        <ul className="tab-title">
            <li className={activeTab === 0 ? 'active' : ''} onClick={() => setActiveTab(0)}>Булан</li>
            <li className={activeTab === 1 ? 'active' : ''} onClick={() => setActiveTab(1)}>Талбар</li>
        </ul>
        <div className="tab-content">
            <div className={activeTab === 0 ? 'elements active' : 'elements'}>
                <Builder root={widgets}
                    renderNode={node => <div>{getValue(node, 'title')}</div >} />
            </div>
            <div className={activeTab === 1 ? 'elements active' : 'elements'}>
                <Builder root={fields}
                    renderNode={node => <div>{getValue(node, 'title')}</div >} />
            </div>
        </div>
    </div>
}
Dg41.After = After;
export default Dg41;