import { FC } from 'react';
import { createPortal } from 'react-dom';
import Element from '../Element';

const Modal: FC<ModalProps> = ({ title, layout, item, visible, onClose }) => {
    return visible ? createPortal(<div className="modal-wrapper">
        <div className='modal'>
            <h3>{title}</h3>
            {layout && <Element properties={layout} item={item} onChange={onClose} />}
            <button className='close' onClick={() => onClose()}>
                <span className='fa fa-times'></span>
            </button>
        </div>
    </div>, document.body) : null;
}
type ModalProps = {
    title: string;
    visible: boolean;
    onClose: any;
    layout: any;
    item: any;
};
export default Modal;