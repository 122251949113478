import { FC } from 'react';
import { MessengerChat } from 'react-messenger-chat-plugin';
import ElementProps from '../../types/ElementProps';
import { getValue } from '../../utils/valueUtils';
// FB messenger
const Dg5020: FC<ElementProps> = ({ properties }) => {
    let props = {
        pageId: getValue(properties, 'pageId'),
        appId: getValue(properties, 'appId'),
        themeColor: getValue(properties, 'color'),
        greetingDialogDelay: getValue(properties, 'delay'),
        greetingDialogDisplay: getValue(properties, 'display'),
        loggedInGreeting: getValue(properties, 'loggedInGreetingText'),
        loggedOutGreeting: getValue(properties, 'loggedOutGreetingText')
    }
    return <MessengerChat {...props} />
}
export default Dg5020;