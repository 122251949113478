import { FC, useContext } from 'react';
import ElementProps from '../../types/ElementProps';
import { SiteContext } from '../../utils/context';
import * as valueUtils from '../../utils/valueUtils';
import Dg8 from './Dg8';
// menu-edit
const Dg11: FC<ElementProps> = ({ properties, item, onChange, value }) => {
	const { site } = useContext(SiteContext);
	const menus = site.values.filter(function (value:any) {
		return value.field === 2;
	});
	const options = Object.fromEntries(menus.map((menu:any, i:number) => [menu.id, valueUtils.getValue(menu.value, 'title')]));
	const propertiesClone = { ...properties };
	properties.values = [...properties.values, { field: 'pairs', value: options }];
	return <Dg8 properties={propertiesClone} value={value} item={item} onChange={onChange} />;
}
export default Dg11;