import { FC, useContext } from 'react';
import SiteService from '../services/SiteService';
import { handleRequestError } from '../utils/responseUtils';
import Dg41 from './edit/Dg41';
import { AuthContext, SiteContext } from '../utils/context';
import { getValue } from '../utils/valueUtils';
import { useParams } from 'react-router-dom';

/**
 * Хуудасны бүтэц өрнө
 * @param {*} props 
 * @returns 
 */
const PageLayout = () => {
  const { site } = useContext(SiteContext);
  const { token } = useContext(AuthContext);
  const { valueId, nodeId } = useParams();
  let title;
  let properties;
  let item;
  // nodeId нь үсэг, тоо байж болно
  var values = site.values.filter(value => value.id === valueId);
  if (values.length > 0) {
    const findNode = (node: any) => {
      if (node.id === nodeId) {
        return node;
      } else if ('items' in node) {
        for (var i in node.items) {
          var result: any = findNode(node.items[i]);
          if (result) {
            return result;
          }
        }
      }
    };
    var node = findNode(values[0].value);
    if (node) {
      title = getValue(node, 'title');
      properties = {
        id: 'layout', values: [{
          field: 'item',
          value: 4
        }, {
          field: 'field',
          value: 7
        }, {
          field: 'multi',
          value: true
        }]
      };
      item = node;
    }
  }

  const save = () => {
    var clear = (category: any) => {
      if ('items' in category) {
        if (category.items.length > 0) {
          category.items.forEach((child: any) => {
            clear(child);
          });
        } else {
          delete category.items;
        }
      }
      if ('path' in category) {
        delete category.path;
      }
      if ('parent' in category) {
        delete category.parent;
      }
    };
    var data = {
      id: site.id,
      category: site.category,
      values: site.values.filter(value => value.field === 1).map(value => {
        // TODO: var result = angular.copy(value);
        var result = value;
        clear(result.value);
        return result;
      })
    };
    new SiteService(token).edit(data).then(() => {
      alert('Амжилттай хадгаллаа.');
    }, handleRequestError);
  }
  return <>
    <h2><i className="fa fa-cog fa-fw"></i> {title}</h2>
    <div className="box">
      <Dg41 properties={properties} item={item} />
    </div>
    <div className="action">
      <a href="categories.html" className="btn btn-default"><i className="fa fa-chevron-left"></i> Болих</a>
      <button className="btn btn-primary" onClick={save}>Хадгалах</button>
    </div>
  </>;
}
export default PageLayout;