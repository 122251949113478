import { useContext, useEffect, useState } from 'react';
import { Link, redirect, useLocation, useParams } from 'react-router-dom';
import ItemService from '../services/ItemService';
import { getCategory, getItemLayout, collectFields, getValue } from '../utils/valueUtils';
import Pagination from './Pagination';
import Element from './Element';
import { AuthContext, SiteContext } from '../utils/context';
import PermissionModal from './modal/PermissionModal';
import ExcelModal from './modal/ExcelModal';

function getUrl(i: any) {
    var url = new URL(window.location.toString());
    var search_params = url.searchParams;
    // new value of "id" is set to "101"
    search_params.set('status', i);
    // change the search property of the main url
    url.search = search_params.toString();
    // the new url string
    return url.pathname + url.search;
}
function Table() {
    const { site } = useContext(SiteContext);
    const { token } = useContext(AuthContext);
    const { categoryId, parentId } = useParams();
    const location = useLocation();
    const [category, setCategory] = useState({} as any);
    const [parent, setParent] = useState({} as any);
    const [items, setItems] = useState([]);
    const [fields, setFields] = useState([] as any[]);
    const [visible, setVisible] = useState(false);
    const [excelVisible, setExcelVisible] = useState(false);
    const [permission, setPermission] = useState(null as any);
    const [title, setTitle] = useState('');
    const url = new URL(window.location.href);
    var q: any;
    var queryParam;
    let status = parseInt(url.searchParams.get('status') || '0');
    status = status > 0 ? status : 30;
    const [pagination, setPagination] = useState({ current: parseInt(url.searchParams.get('page') || '0') > 1 ? parseInt(url.searchParams.get('page') || '0') : 1, size: 0, total: 0 });
    const hasForm = () => {
        return category && getItemLayout(site, category, 'form').items?.length > 0;
    };
    const changePage = (current: number, status: number) => {
        q = {
            status: status,
            category: parseInt(categoryId || '0'),
            $orderby: { created: 'desc' }
        };
        console.log('change to ', q.category, document.location.href);
        if (parseInt(parentId || '0') > 0) {
            q.parent = parentId;
        }
        queryParam = { limit: 50, q: JSON.stringify(q), offset: 0 };
        queryParam.offset = (current - 1) * queryParam.limit;
        new ItemService(token).query(queryParam).then(result => result.json()).then(function (value) {
            setItems(value.content);
            setPagination({ current: current, size: value.count, total: value.total });
        });
    };
    const remove = (item: any) => {
        if (window.confirm('Та "' + item.title + '" мэдээллийг устгаж байгаадаа итгэлтэй байна уу?')) {
            new ItemService(token).remove(item).then(() => {
                setItems(items.filter(itm => itm !== item));
            });
        }
    };
    const changeParam = (category: any) => {
        setVisible(true);
        setPermission({
            permission: category.param && category.param.itemPermission ?
                category.param.itemPermission : {},
            //TODO: defperm: site.defaults[0].param.itemPermission
            defperm: site.default.param
        });
        setTitle(getValue(category, 'title') + ' - ангилалд хандах эрх');
    };
    const onPermissionClose = (permission: any) => {
        if (!('param' in category)) {
            category.param = {};
        }
        category.param.itemPermission = permission;
    }
    const excel = () => {
        setExcelVisible(true);
    };
    const onExcelClose = (result: any) => {
        setItems(items.concat(result));
    };
    useEffect(() => {
        console.log('category changed ', categoryId);
        const cat = getCategory(site, parseInt(categoryId || '0'));
        if (cat) {
            var value = getItemLayout(site, cat, 'teaser');
            setFields(collectFields(value.items));
            changePage(pagination.current, status);
        }
        setCategory(cat);
    }, [categoryId]);

    // Идэвхтэй хуудасны өөрчлөлтийг барьж, төлөв болон хуудсыг өөрчилнө.
    useEffect(() => {
        console.log('history listen', location);
        const newUrl = new URL(location.pathname + location.search, window.location.toString());
        status = parseInt(newUrl.searchParams.get('status') || '0');
        let page = parseInt(newUrl.searchParams.get('page') || '0');
        changePage(page > 1 ? page : 1, status > 0 ? status : 30);
    }, [location]);
    const renderHeader = () => <tr>
        <th className="action"></th>
        <th className="id">№</th>
        {fields.map((field, i) => <th key={i}>{getValue(field, 'title')}</th>)}
        <th className="action"></th>
    </tr>
    return <>
        <div className="action">
            <Link to={getUrl(30)} className={status === 30 ? 'btn btn-primary' : 'btn'} title="Нийтэлсэн">
                <i className="fa fa-eye"></i></Link>
            <Link to={getUrl(20)} className={status === 20 ? 'btn btn-primary' : 'btn'} title="Нийтлээгүй">
                <i className="fa fa-eye-slash"></i></Link>
            <Link to={getUrl(10)} className={status === 10 ? 'btn btn-primary' : 'btn'} title="Хаалттай">
                <i className="fa fa-ban"></i></Link>
            {hasForm() && <a href="" onClick={excel} className="btn btn-default">
                <i className="fa fa-file-excel-o"></i> Excel-ээс нэмэх</a>}
            {hasForm() && <Link to={parent.id ? "/items/" + parent.id +
                "/categories/" + category.id + "/items/new/form.html" :
                '/categories/' + category.id + '/items/new/form.html'} className="btn btn-success">
                <i className="fa fa-plus"></i> Шинийг нэмэх</Link>}
            <a href="" className="btn btn-default" onClick={changeParam} title="Тохируулах">
                <i className="fa fa-gear"></i></a>
        </div>
        <Pagination total={pagination.total} count={items.length}
            size={pagination.size} current={pagination.current}></Pagination>
        <div className="table-wrapper">
            <table className="table table-striped table-bordered table-hover">
                <thead>
                    {renderHeader()}
                </thead>
                <tbody>
                    {items.map((item: any) => <tr key={item.id}>
                        <td className="edit">
                            <Link className="btn btn-primary btn-sm" to={'/items/' + item.id + '/form.html'}><i className="fa fa-pencil"></i></Link>
                        </td>
                        <td className="id">{item.id}</td>
                        {fields.map((field: any, i: number) => <td key={i} className={'f' + field.id}><Element properties={field} item={item} /></td>)}
                        <td className="remove">
                            <button className="btn btn-danger btn-sm" onClick={() => { remove(item) }}><i className="fa fa-times"></i></button>
                        </td>
                    </tr>)}
                </tbody>
                <tfoot>
                    {renderHeader()}
                </tfoot>
            </table>
        </div>
        <Pagination total={pagination.total} count={items.length}
            size={pagination.size} current={pagination.current}></Pagination>
        <PermissionModal title={title} visible={visible} onClose={onPermissionClose} item={permission} />
        {category && category.id ? <ExcelModal category={category} pntId={parseInt(parentId || '0')} visible={excelVisible} onClose={onExcelClose} item={undefined} title={''} /> : null}
    </>
}
export default Table;