import { FC, useContext, useEffect, useState } from 'react';
import { getCategory, getItemLayout, setMetaTags } from '../utils/valueUtils';
import ItemService from '../services/ItemService';
import UserService from '../services/UserService';
import Element from './Element';
import Item from '../types/Item';
import { AuthContext, SiteContext } from '../utils/context';
const ItemElement: FC<ItemProps> = ({ layoutName, item }) => {
    const { site } = useContext(SiteContext);
    const { token } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [layout, setLayout] = useState({} as any);
    const [_item, setItem] = useState(item);
    useEffect(() => {
        // Мэдээллийн ангиллаас хамаарч харуулах хэлбэрийг өөрчилнө
        const category = getCategory(site, _item.category);
        if (category) {
            setLayout(getItemLayout(site, category, layoutName));
        }
    }, [layoutName, _item.category]);

    useEffect(() => {
        if (item.category === 0) {
            // Вэб сайт бол
            setItem(item);
        } else if (item.category === -3) {
            // Хэрэглэгч бол
            const itemId = item.id ?? 0;
            if (itemId > 0 && !('created' in item)) {
                setIsLoading(true);
                new UserService(token).get(itemId).then(function (value: any) {
                    setItem({ ...value });
                    setIsLoading(false);
                    setTimeout(function () {
                        setMetaTags(layout.items, item);
                    }, 0);
                });
            }
        } else if (item.id != undefined && item.id > 0 && !('created' in item)) {
            setIsLoading(true);
            new ItemService(token).get(item.id).then(response => response.json()).then(value => {
                setItem({ ...value });
                setIsLoading(false);
            }).catch(() => {
                setIsLoading(false);
            });
        }
    }, [item, layoutName]);
    return <Element properties={layout} item={_item} />
}
type ItemProps = {
    item: Item;
    layoutName: string;
    onSave?: any;
    onCancel?: any;
}
export default ItemElement;