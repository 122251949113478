import { FC } from 'react';
import ElementProps from '../../types/ElementProps';
/**
 * text-view
 * @param param0 
 * @returns 
 */
const Dg1001: FC<ElementProps> = ({ properties, item, value }) => {
    return <>{value.value}</>;
}
export default Dg1001;